@tailwind base;
@tailwind components;
@tailwind utilities;

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}


@layer components {
    .topBar {
        @apply flex w-full border-b-[2px] border-b-[#157b4b] items-center h-[50px] fixed top-0 left-0 bg-[#f6fbf7] z-10;
    }
    .base_text {
        @apply text-[12px] text-black/60;
    }
    .footer {
        @apply w-full bg-[#32A086] py-4 px-4 font-normal flex fixed bottom-0 left-0;
    }
    .text_white {
        @apply text-white text-[11px];
    }
    .h2_pb2 {
        @apply  text-[11px];
    }
    .title_text {
        @apply text-[20px] font-bold;
    }
    .sub_title {
        @apply text-[16px] font-bold
    }
    .li_styles {
        @apply relative pl-[12px];
    }
    .bg_color {
        @apply bg-[#157b4b];
    }
    .text_color {
        @apply text-[#157b4b];
    }
    .main {
        @apply flex flex-col pt-[80px] px-8 items-center bg-[#f3f3f3]/20 pb-8;
    }
    .progressBorder {
        @apply w-[16px] h-[16px] rounded-full bg-[#f6fbf7] border-[2px]
    }
    .before_line {
        @apply before:content-[''] before:absolute before:bg-[#eee] before:w-full before:h-[2px] before:z-[-1];
    }
    .button_off {
        @apply bg-[#32A086]/60 w-[124px] py-2 rounded-lg text-white font-normal;
    }
    .button_on {
        @apply bg-[#32A086] w-[124px] py-2 rounded-lg text-white font-normal;
    }
    .input {
        @apply w-[230px] text-[12px] py-2 px-4 border focus:outline-none hover:outline-none hover:border-[#32A086] rounded-lg;
    }
    .checkOff {
        @apply border w-[18px] h-[18px] rounded-full flex justify-center items-center;
    }
    .checkOn {
        @apply border w-[18px] h-[18px] rounded-full flex justify-center items-center bg-[#32A086];
    }
    .bold_12 {
        @apply text-[12px] font-bold;
    }
}

